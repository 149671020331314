import Api from "../apis/Api";
import ActionModalMixin from "./ActionModalMixin";

const ConfigActionsMixin = {
  mixins: [ActionModalMixin],
  methods: {
    // DELETE ACTIONS
    showDeleteModal(item) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.instance.advanced.wordpressConfig.modal.delete.title');
      this.modalOptions.message = this.$t('heading.instance.advanced.wordpressConfig.modal.delete.info', {name: item.name});
      this.modalOptions.icon = "$alertwarning";
      this.modalOptions.color = "error";

      this.modalOptions.formFields = [
        {
          message: "<b>" +  this.$t('message.confirmAction') + "</b>",
          label: this.$t('form.confirmDelete.wordpressConfig'),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];

      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: () => {
          this.deleteConfig(item);
        },
      });
      this.modalOptions.item = item;
      this.modalOptions.open = true;
    },
    deleteConfig(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(
        `/instances/${this.instanceId}/wordpress/config/${item.name}`,
        {
          data: { type: item.type },
        }
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.instance.wordpressConfig.delete.success')

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });

          this.reloadConfig();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    // CREATE ACTIONS
    showCreateModal() {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.instance.advanced.wordpressConfig.modal.create.title');
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.create'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.createConfig(modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.name'),
          tooltip: "tooltip.create_config.name",
          name: "name",
          type: "text",
          placeholder: this.$t('form.label.name'),
          rules: [(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.name')})],
        },
        {
          label: this.$t('form.label.value'),
          tooltip: "tooltip.create_config.value",
          name: "value",
          type: "text",
          placeholder: this.$t('form.placeholder.value'),
          rules: [(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.value')})],
        },
        {
          label: this.$t('form.label.type'),
          tooltip: "tooltip.create_config.type",
          name: "type",
          type: "select",
          rules: [(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.type')})],
          options: [
            { label: this.$t('form.option.constant'), value: "constant" },
            { label: this.$t('form.option.variable'), value: "variable" },
          ],
        },
      ];
      this.modalOptions.open = true;
    },
    createConfig: function (formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.post(`/instances/${this.instanceId}/wordpress/config`, formData)
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.instance.wordpressConfig.create.success')

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });

          this.reloadConfig();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    // EDIT ACTIONS
    showEditModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.instance.advanced.wordpressConfig.modal.edit.title')
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.updateConfig(item, modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.name'),
          tooltip: "tooltip.create_config.name",
          name: "name",
          type: "text",
          placeholder: this.$t('form.placeholder.name'),
          rules: [(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.name')})],
        },
        {
          label: this.$t('form.label.value'),
          tooltip: "tooltip.create_config.value",
          name: "value",
          type: "text",
          placeholder: this.$t('form.label.value'),
          rules: [(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.value')})],
        },
        {
          label: this.$t('form.label.type'),
          tooltip: "tooltip.create_config.type",
          name: "type",
          type: "select",
          rules: [(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.type')})],
          options: [
            { label: this.$t('form.option.constant'), value: "constant" },
            { label: this.$t('form.option.variable'), value: "variable" },
          ],
        },
      ];
      this.modalOptions.open = true;
    },
    updateConfig(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.put(
        `/instances/${this.instanceId}/wordpress/config/${item.name}`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.instance.wordpressConfig.update.success')
          this.reloadConfig();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
  },
};

export default ConfigActionsMixin;
